import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('mouseout', this.revertToDefaultValue.bind(this));
    this.element.addEventListener('mouseover', this.updateToHoverValue.bind(this));
    this.updateTarget = document.getElementById(this.element.dataset.updateTarget);
    this.defaultText = this.element.dataset.defaultText;
    this.hoverText = this.element.dataset.hoverText;
  }

  updateToHoverValue() {
    if (!this.hoverText) return;
    this.updateTarget.innerHTML = this.hoverText;
  }

  revertToDefaultValue() {
    this.updateTarget.innerHTML = this.defaultText || '';
  }
}
