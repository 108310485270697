import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input", "countOfwordsRemaining"];

  connect() {
    for (const eventName of ['keypress', 'paste', 'change']) {
      this.element.addEventListener(eventName, this.updateCount.bind(this));
    }
    this.element.addEventListener('keypress paste change', this.updateCount.bind(this));
    this.initialCount = parseInt(this.element.dataset.initialCount);
  }

  updateCount() {
    const wordCount = this.inputTarget.value.trim().split(/\b[\s,\.-:;]*/).length;
    this.countOfwordsRemainingTarget.textContent = this.initialCount - wordCount;
  }
}