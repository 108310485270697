import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('focusout', this.submit.bind(this));
    this.form = this.element.closest('form');
  }

  submit() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const formData = new FormData(this.form)
    formData.append('authenticity_token', csrfToken);
    fetch(this.form.action, {
        headers: { Accept: "text/vnd.turbo-stream.html" },
        method: this.form.method,
        body: formData
      }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html));
  }

}

